import {
  Row,
  Typography,
  Col,
  Button,
  Pagination,
  Spin,
  Popover,
  Modal,
  Form,
  Input,
  Empty,
} from "antd";
import {
  getFacilities,
  getAppliances,
  getITAppliances,
  getMedicalAppliances,
  getFields,
} from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddFacility from "./addFacility";
import EditFacilities from "./editFacility";
import AddAppliance from "./addAppliance";
import AddITAppliance from "./addITAppliance";
import EditAppliance from "./editAppliance";
import EditITAppliance from "./editITAppliance";
import AddMedAppliance from "./addMedAppliance";
import EditMedAppliance from "./editMedAppliance";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import ExitModal from "./exitModal";
import MoreActions from "../../../../../extraComponents/MoreActions";

const { Text } = Typography;

export default function RoomChart(props) {
  const { setMainPage, mainStates, setMainStates } = props;
  const [facilities, setFacilities] = useState([]);
  const [appliances, setAppliances] = useState([]);
  const [medAppliances, setMedAppliances] = useState([]);
  const [ITAppliances, setITAppliances] = useState([]);
  const [addFacilities, setAddFacilities] = useState(false);
  const [editFacilities, setEditFacilities] = useState(null);
  const [addAppliance, setAddAppliance] = useState(false);
  const [editAppliance, setEditAppliance] = useState(null);
  const [addMedAppliance, setAddMedAppliance] = useState(false);
  const [editMedAppliance, setEditMedAppliance] = useState(null);
  const [addITAppliance, setAddITAppliance] = useState(false);
  const [editITAppliance, setEditITAppliance] = useState(null);
  const [count, setCount] = useState(0);
  const [countApp, setCountApp] = useState(0);
  const [countMed, setCountMed] = useState(0);
  const [countIT, setCountIT] = useState(0);
  const [page, setPage] = useState(1);
  const [pageApp, setPageApp] = useState(1);
  const [pageMed, setPageMed] = useState(1);
  const [pageIT, setPageIT] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [updateDataApp, setUpdateDataApp] = useState(false);
  const [updateDataMed, setUpdateDataMed] = useState(false);
  const [updateDataIT, setUpdateDataIT] = useState(false);
  const [open, setOpen] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [fields, setFields] = useState({});
  const [exitObject, setExitObject] = useState(null);

  useEffect(async () => {
    setLoad(true);
    const facilities_ = await getFacilities((page - 1) * 5, mainStates?.room);

    if (facilities_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setFacilities(facilities_?.list ? facilities_?.list : []);
    setCount(facilities_?.count ? facilities_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

  useEffect(async () => {
    const group_type = await getFields("G01C04D01F01");
    const condition = await getFields("G01C08D00F02");
    const check_necessary = await getFields("G01C08D00F10");
    const check_necessary_appl = await getFields("G01C09D00F01");
    const check_interval = await getFields("G01C08D00F17");
    const appliance_type = await getFields("G01C09D00F04");
    const appliance_group = await getFields("G01C09D00F03");
    setFields({
      ...fields,
      condition: condition,
      group_type: group_type,
      check_necessary: check_necessary,
      check_necessary_appl: check_necessary_appl,
      check_interval: check_interval,
      appliance_type: appliance_type,
      appliance_group: appliance_group,
    });
  }, []);

  useEffect(async () => {
    setLoad(true);
    const appliances_ = await getAppliances(
      (pageApp - 1) * 5,
      mainStates?.room
    );

    if (appliances_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setAppliances(appliances_?.list ? appliances_?.list : []);
    setCountApp(appliances_?.count ? appliances_?.count : 0);
    setLoad(false);
  }, [pageApp, updateDataApp]);

  useEffect(async () => {
    setLoad(true);
    const appliances_ = await getITAppliances(
      (pageIT - 1) * 5,
      mainStates?.room
    );
    if (appliances_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setITAppliances(appliances_?.list ? appliances_?.list : []);
    setCountIT(appliances_?.count ? appliances_?.count : 0);
    setLoad(false);
  }, [pageIT, updateDataIT]);

  useEffect(async () => {
    setLoad(true);
    const appliances_ = await getMedicalAppliances(
      (pageMed - 1) * 5,
      mainStates?.room
    );
    if (appliances_.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setMedAppliances(appliances_?.list ? appliances_?.list : []);
    setCountMed(appliances_?.count ? appliances_?.count : 0);
    setLoad(false);
  }, [pageMed, updateDataMed]);

  return (
    <>
      <AddFacility
        open={addFacilities}
        setOpen={setAddFacilities}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        parent={mainStates?.room}
        fields={fields}
      />
      <EditFacilities
        facility={editFacilities}
        setFacility={setEditFacilities}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        parent={mainStates?.room}
        fields={fields}
      />
      <AddAppliance
        open={addAppliance}
        setOpen={setAddAppliance}
        setPage={setPageApp}
        setLoad={setLoad}
        update={updateDataApp}
        setUpdate={setUpdateDataApp}
        parent={mainStates?.room}
        fields={fields}
      />
      <EditAppliance
        appliance={editAppliance}
        setAppliance={setEditAppliance}
        setLoad={setLoad}
        update={updateDataApp}
        setUpdate={setUpdateDataApp}
        fields={fields}
      />
      <AddITAppliance
        open={addITAppliance}
        setOpen={setAddITAppliance}
        setLoad={setLoad}
        update={updateDataIT}
        setUpdate={setUpdateDataIT}
        parent={mainStates?.room}
        fields={fields}
      />
      <EditITAppliance
        appliance={editITAppliance}
        setAppliance={setEditITAppliance}
        setLoad={setLoad}
        update={updateDataIT}
        setUpdate={setUpdateDataIT}
        parent={mainStates?.room}
        fields={fields}
      />
      <AddMedAppliance
        open={addMedAppliance}
        setOpen={setAddMedAppliance}
        setLoad={setLoad}
        update={updateDataMed}
        setUpdate={setUpdateDataMed}
        parent={mainStates?.room}
        fields={fields}
      />
      <EditMedAppliance
        appliance={editMedAppliance}
        setAppliance={setEditMedAppliance}
        setLoad={setLoad}
        update={updateDataMed}
        setUpdate={setUpdateDataMed}
        fields={fields}
      />

      {/* <Modal
        title={"Exit Object"}
        width="960"
        visible={openModal}
        centered
        destroyOnClose
        okText="Exit Object"
        onOk={() => {
          // ref?.current?.submit();
          setOpenModal(!openModal);
          setOpen(!open);
        }}
        onCancel={() => {
          setOpenModal(!openModal);
          setOpen(!open);
        }}
      >
        <Form
          name="exit object"
          // ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          onFinish={async (values) => {
            setOpen(!open);
          }}
        >
          <Text>If you want to exit this object, please enter a reason:</Text>
          <FormItem label="Exit Reason" name="exit" style={{ marginBottom: 0 }}>
            <Input />
          </FormItem>
          <FormItem label="Comment" name="comment" style={{ marginBottom: 0 }}>
            <Input />
          </FormItem>
        </Form>
      </Modal> */}
      <ExitModal
        open={open}
        setOpen={setOpen}
        openModal={openModal}
        setOpenModal={setOpenModal}
        exitObject={exitObject}
        parent={mainStates?.room}
        update={
          exitObject === "facility"
            ? updateData
            : exitObject === "appliance"
            ? updateDataApp
            : exitObject === "ITAppliance"
            ? updateDataIT
            : updateDataMed
        }
        setUpdate={
          exitObject === "facility"
            ? setUpdateData
            : exitObject === "appliance"
            ? setUpdateDataApp
            : exitObject === "ITAppliance"
            ? setUpdateDataIT
            : setUpdateDataMed
        }
        setLoad={setLoad}
      />

      <Text
        style={{
          color: "#038fde",
        }}
      >
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMainPage(1);
          }}
        >
          {mainStates?.estateName ? (
            mainStates?.estateName
          ) : (
            <IntlMessages id="estate.title" />
          )}
        </span>
        <span
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setMainPage(2);
          }}
        >
          {` -> ${
            mainStates?.buildingName ? (
              mainStates?.buildingName
            ) : (
              <IntlMessages id="building.title" />
            )
          }`}
        </span>
        <span
          onClick={() => {
            setMainPage(3);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          {` -> ${
            mainStates?.floorName ? (
              mainStates?.floorName
            ) : (
              <>
                <IntlMessages id="floor.title" /> /{" "}
                <IntlMessages id="machine.title" />
              </>
            )
          }`}
        </span>
        <span
          onClick={() => {
            setMainPage(4);
          }}
          style={{
            cursor: "pointer",
          }}
        >
          {` -> ${
            mainStates?.roomName ? (
              mainStates?.roomName
            ) : (
              <IntlMessages id="room.title" />
            )
          }" ->`}
        </span>
        <IntlMessages id="room_chart" />
      </Text>
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <>
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: "20px 0 0 0",
              }}
            >
              <Col
                xs={12}
                sm={14}
                xl={18}
                style={{
                  padding: 0,
                }}
              >
                <Text>
                  {" "}
                  <IntlMessages id="facility.title" />
                </Text>
              </Col>

              <Col xs={12} sm={10} xl={6}>
                <Text>
                  {" "}
                  <IntlMessages id="actions" />
                </Text>
              </Col>
            </Row>

            {facilities?.length === 0 ? (
              <Empty description="Keine Ausstattungen vorhanden!" />
            ) : null}

            {facilities?.map((facility) => {
              return (
                <Row
                  style={{
                    backgroundColor: "#fff",
                    borderBottom: "1px solid #eaeaea",
                    padding: "10px 30px",
                    margin: "0",
                  }}
                >
                  <Col
                    xs={12}
                    sm={14}
                    xl={18}
                    style={{
                      padding: 0,
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setEditFacilities(facility);
                    }}
                  >
                    <Text
                      style={{
                        color: "#038fde",
                      }}
                    >
                      {facility?.cfeld1}
                    </Text>
                    <p style={{ margin: 0, fontSize: "12px" }}>
                      {facility?.cfeld2}
                    </p>
                  </Col>

                  <Col>
                    <Text
                      style={{
                        color: "#038fde",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setOpen(facility);
                        setExitObject("facility");
                      }}
                    >
                      <IntlMessages id="more_actions" />
                    </Text>
                    <DownOutlined
                      size="small"
                      style={{
                        color: "#038fde",
                        paddingLeft: "10px",
                        fontSize: "10px",
                      }}
                    />

                    {facility.detkey === open?.detkey ? (
                      <Popover
                        content={
                          <Text
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setOpenModal(!openModal);
                            }}
                          >
                            Exit Object
                          </Text>
                        }
                        trigger="click"
                        visible={open}
                        onVisibleChange={() => {
                          setOpen(null);
                        }}
                      ></Popover>
                    ) : null}
                  </Col>
                </Row>
              );
            })}
            <Row
              style={{
                backgroundColor: "#FAFAFA",
                borderBottom: "1px solid #eaeaea",
                fontWeight: 500,
                padding: "10px 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Button
                size="small"
                type="primary"
                style={{
                  margin: 0,
                }}
                onClick={() => {
                  setAddFacilities(!addFacilities);
                }}
              >
                <IntlMessages id="facility.add" />
              </Button>
            </Row>
            <Row
              style={{
                backgroundColor: "#fff",
                padding: "10px 30px 0 30px",
                margin: "0",
              }}
              justify="end"
            >
              <Pagination
                defaultCurrent={1}
                total={count}
                current={page}
                defaultPageSize={5}
                style={{
                  margin: 0,
                }}
                onChange={(newpage) => {
                  setPage(newpage);
                }}
              />
            </Row>
          </>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <>
              <Row
                style={{
                  backgroundColor: "#FAFAFA",
                  borderBottom: "1px solid #eaeaea",
                  fontWeight: 500,
                  padding: "10px 30px",
                  margin: "20px 0 0 0",
                }}
              >
                <Col
                  xs={12}
                  sm={14}
                  xl={18}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {" "}
                    <IntlMessages id="appliance.title" />
                  </Text>
                </Col>

                <Col xs={12} sm={10} xl={6}>
                  <Text>
                    {" "}
                    <IntlMessages id="actions" />
                  </Text>
                </Col>
              </Row>

              {appliances?.length === 0 ? (
                <Empty description="Keine Geräte vorhanden!" />
              ) : null}

              {appliances?.map((appliance) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      borderBottom: "1px solid #eaeaea",
                      padding: "10px 30px",
                      margin: "0",
                    }}
                  >
                    <Col
                      xs={12}
                      sm={14}
                      xl={18}
                      style={{
                        padding: 0,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditAppliance(appliance);
                      }}
                    >
                      <Text
                        style={{
                          color: "#038fde",
                        }}
                      >
                        {appliance?.cfeld1}
                      </Text>
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        {appliance?.cfeld3} {appliance?.cfeld4}
                      </p>
                    </Col>
                    <Col>
                      <MoreActions
                        state={appliance}
                        inspection={true}
                        repair={true}
                        exitModal={openModal}
                        setExitModal={setOpenModal}
                        setExitObject={setExitObject}
                        setOpen={setOpen}
                        stClass={"9"}
                      />
                      <DownOutlined
                        size="small"
                        style={{
                          color: "#038fde",
                          paddingLeft: "10px",
                          fontSize: "10px",
                        }}
                      />
                    </Col>

                    {/* <Col>
                      <Text
                        style={{
                          color: "#038fde",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpen(appliance);
                          setExitObject("appliance");
                        }}
                      >
                        <IntlMessages id="more_actions" />
                      </Text>
                      <DownOutlined
                        size="small"
                        style={{
                          color: "#038fde",
                          paddingLeft: "10px",
                          fontSize: "10px",
                        }}
                      />
                      {appliance.stkey === open?.stkey &&
                      exitObject === "appliance" ? (
                        <Popover
                          content={
                            <Text
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpenModal(!openModal);
                              }}
                            >
                              Exit Object
                            </Text>
                          }
                          trigger="click"
                          visible={open}
                          onVisibleChange={() => {
                            setOpen(null);
                          }}
                        ></Popover>
                      ) : null}
                    </Col> */}
                  </Row>
                );
              })}
              <Row
                style={{
                  backgroundColor: "#FAFAFA",
                  borderBottom: "1px solid #eaeaea",
                  fontWeight: 500,
                  padding: "10px 30px",
                  margin: "0",
                }}
                justify="end"
              >
                <Button
                  size="small"
                  type="primary"
                  style={{
                    margin: 0,
                  }}
                  onClick={() => {
                    setAddAppliance(!addAppliance);
                  }}
                >
                  <IntlMessages id="appliance.add" />
                </Button>
              </Row>
              <Row
                style={{
                  backgroundColor: "#fff",
                  padding: "10px 30px 0 30px",
                  margin: "0",
                }}
                justify="end"
              >
                <Pagination
                  defaultCurrent={1}
                  total={countApp}
                  current={pageApp}
                  defaultPageSize={5}
                  style={{
                    margin: 0,
                  }}
                  onChange={(newpage) => {
                    setPageApp(newpage);
                  }}
                />
              </Row>
            </>
          </div>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <>
              <Row
                style={{
                  backgroundColor: "#FAFAFA",
                  borderBottom: "1px solid #eaeaea",
                  fontWeight: 500,
                  padding: "10px 30px",
                  margin: "20px 0 0 0",
                }}
              >
                <Col
                  xs={12}
                  sm={14}
                  xl={18}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {" "}
                    <IntlMessages id="it_appliance.title" />
                  </Text>
                </Col>

                <Col xs={12} sm={10} xl={6}>
                  <Text>
                    {" "}
                    <IntlMessages id="actions" />
                  </Text>
                </Col>
              </Row>

              {ITAppliances?.length === 0 ? (
                <Empty description="Keine IT Geräte vorhanden!" />
              ) : null}

              {ITAppliances?.map((appliance) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      borderBottom: "1px solid #eaeaea",
                      padding: "10px 30px",
                      margin: "0",
                    }}
                  >
                    <Col
                      xs={12}
                      sm={14}
                      xl={18}
                      style={{
                        padding: 0,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditITAppliance(appliance);
                      }}
                    >
                      <Text
                        style={{
                          color: "#038fde",
                        }}
                      >
                        {appliance?.cfeld1}
                      </Text>
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        {appliance?.cfeld2}
                      </p>
                    </Col>

                    <Col>
                      <Text
                        style={{
                          color: "#038fde",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpen(appliance);
                          setExitObject("ITAppliance");
                        }}
                      >
                        <IntlMessages id="more_actions" />
                      </Text>
                      <DownOutlined
                        size="small"
                        style={{
                          color: "#038fde",
                          paddingLeft: "10px",
                          fontSize: "10px",
                        }}
                      />
                      {appliance.detkey === open?.detkey ? (
                        <Popover
                          content={
                            <Text
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpenModal(!openModal);
                              }}
                            >
                              Exit Object
                            </Text>
                          }
                          trigger="click"
                          visible={open}
                          onVisibleChange={() => {
                            setOpen(null);
                          }}
                        ></Popover>
                      ) : null}
                    </Col>
                  </Row>
                );
              })}
              <Row
                style={{
                  backgroundColor: "#FAFAFA",
                  borderBottom: "1px solid #eaeaea",
                  fontWeight: 500,
                  padding: "10px 30px",
                  margin: "0",
                }}
                justify="end"
              >
                <Button
                  size="small"
                  type="primary"
                  style={{
                    margin: 0,
                  }}
                  onClick={() => {
                    setAddITAppliance(!addITAppliance);
                  }}
                >
                  <IntlMessages id="it_appliance.add" />
                </Button>
              </Row>
              <Row
                style={{
                  backgroundColor: "#fff",
                  padding: "10px 30px 0 30px",
                  margin: "0",
                }}
                justify="end"
              >
                <Pagination
                  defaultCurrent={1}
                  total={countIT}
                  current={pageIT}
                  defaultPageSize={5}
                  style={{
                    margin: 0,
                  }}
                  onChange={(newpage) => {
                    setPageIT(newpage);
                  }}
                />
              </Row>
            </>
          </div>
          <div
            style={{
              marginTop: "10px",
            }}
          >
            <>
              <Row
                style={{
                  backgroundColor: "#FAFAFA",
                  borderBottom: "1px solid #eaeaea",
                  fontWeight: 500,
                  padding: "10px 30px",
                  margin: "20px 0 0 0",
                }}
              >
                <Col
                  xs={12}
                  sm={14}
                  xl={18}
                  style={{
                    padding: 0,
                  }}
                >
                  <Text>
                    {" "}
                    <IntlMessages id="med_appliance.title" />
                  </Text>
                </Col>

                <Col xs={12} sm={10} xl={6}>
                  <Text>
                    {" "}
                    <IntlMessages id="actions" />
                  </Text>
                </Col>
              </Row>

              {medAppliances?.length === 0 ? (
                <Empty description="Keine med Produkte vorhanden!" />
              ) : null}

              {medAppliances?.map((appliance) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      borderBottom: "1px solid #eaeaea",
                      padding: "10px 30px",
                      margin: "0",
                    }}
                  >
                    <Col
                      xs={12}
                      sm={14}
                      xl={18}
                      style={{
                        padding: 0,
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setEditMedAppliance(appliance);
                      }}
                    >
                      <Text
                        style={{
                          color: "#038fde",
                        }}
                      >
                        {appliance?.cfeld5} / {appliance?.cfeld1}
                      </Text>
                      <p style={{ margin: 0, fontSize: "12px" }}>
                        {appliance?.cfeld3} / {appliance?.cfeld4}
                      </p>
                    </Col>

                    <Col xs={0} sm={8} xl={4}>
                      <Col>
                        <MoreActions
                          state={appliance}
                          inspection={true}
                          repair={true}
                          exitModal={openModal}
                          setExitModal={setOpenModal}
                          setExitObject={setExitObject}
                          setOpen={setOpen}
                          stClass={"8"}
                        />
                        <DownOutlined
                          size="small"
                          style={{
                            color: "#038fde",
                            paddingLeft: "10px",
                            fontSize: "10px",
                          }}
                        />
                      </Col>

                      {/* <Text
                        style={{
                          color: "#038fde",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setOpen(appliance);
                          setExitObject("MedAppliance");
                        }}
                      >
                        <IntlMessages id="more_actions" />
                      </Text>
                      <DownOutlined
                        size="small"
                        style={{
                          color: "#038fde",
                          paddingLeft: "10px",
                          fontSize: "10px",
                        }}
                      /> */}
                      {/* {appliance.stkey === open?.stkey &&
                      exitObject === "MedAppliance" ? (
                        <Popover
                          content={
                            <Text
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setOpenModal(!openModal);
                              }}
                            >
                              Exit Object
                            </Text>
                          }
                          trigger="click"
                          visible={open}
                          onVisibleChange={() => {}}
                        ></Popover>
                      ) : null} */}
                    </Col>
                  </Row>
                );
              })}
              <Row
                style={{
                  backgroundColor: "#FAFAFA",
                  borderBottom: "1px solid #eaeaea",
                  fontWeight: 500,
                  padding: "10px 30px",
                  margin: "0",
                }}
                justify="end"
              >
                <Button
                  size="small"
                  type="primary"
                  style={{
                    margin: 0,
                  }}
                  onClick={() => {
                    setAddMedAppliance(!addMedAppliance);
                  }}
                >
                  <IntlMessages id="med_appliance.add" />
                </Button>
              </Row>
              <Row
                style={{
                  backgroundColor: "#fff",
                  padding: "10px 30px 0 30px",
                  margin: "0",
                }}
                justify="end"
              >
                <Pagination
                  defaultCurrent={1}
                  total={countMed}
                  current={pageMed}
                  defaultPageSize={5}
                  style={{
                    margin: 0,
                  }}
                  onChange={(newpage) => {
                    setPageMed(newpage);
                  }}
                />
              </Row>
            </>
          </div>{" "}
        </>
      ) : null}
    </>
  );
}
