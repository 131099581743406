import { Modal, message } from "antd";
import { useEffect, useState } from "react";
import PhotoAction from "../../extraComponents/MoreActions/components/PhotoAction";
import {
  getPicture,
  uploadImage,
} from "../../extraComponents/MoreActions/network_requests";
import Camera, { FACING_MODES } from "react-html5-camera-photo";

export default function AddPhoto(props) {
  const {
    isModalVisible,
    setIsModalVisible,
    state,
    file,
    setFile,
    image,
    setImage,
    update,
    setUpdate,
    pictures,
    photo,
    setPhoto,
    cameraOpen,
    setCameraOpen,
    uploadProps,
    deleteImage,
    open,
    setOpen,
  } = props;

  useEffect(async () => {
    if (file) {
      const upload = await uploadImage({
        file: file,
        objID: state?.objStkey,
        stclass: "8",
        group: "1",
      });
      setFile(null);
      if (upload?.success === "saved") {
        message.success("Image Uploaded Successfully");
        setImage(null);
        setIsModalVisible(!isModalVisible);
        setUpdate(!update);
      } else {
        message.error("An error occurred while performing action");
      }

      setIsModalVisible(!isModalVisible);
    }
  }, [file]);

  useEffect(async () => {
    if (photo) {
      setIsModalVisible(!isModalVisible);
      const picture_ = await getPicture({
        imageName: photo?.cfeld1,
        objID: photo?.gisid,
        attribute: "none",
        stclass: photo?.nfeld2,
        group: photo?.nfeld1,
      });
      setImage(picture_);
    }
  }, [photo]);
  return (
    <>
      <Modal
        title={`Photo ${state?.cfeld1 ? state?.cfeld1 : state?.checkName}`}
        visible={Boolean(photo)}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setImage(null);
          setPhoto(null);
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setImage(null);
          setPhoto(null);
          setIsModalVisible(!isModalVisible);
        }}
      >
        <img
          src={`data:image/jpeg;base64,${image?.file}`}
          style={{ width: "100%", height: "100%" }}
        />
      </Modal>
      {cameraOpen ? (
        <Camera
          isImageMirror={false}
          idealFacingMode={FACING_MODES.ENVIRONMENT}
          idealResolution={{ height: 303, width: 290 }}
          onTakePhoto={(dataUri) => {
            // dispatch(setCamera({ ...camera, isOpen: false }));
            setFile(dataUri);
            setCameraOpen(!cameraOpen);
            setIsModalVisible(!isModalVisible);
          }}
        />
      ) : null}
      <Modal
        title={`Photo ${state?.cfeld1 ? state?.cfeld1 : state?.checkName}`}
        width={550}
        visible={isModalVisible}
        okButtonProps={{ style: { display: "none" } }}
        onOk={() => {
          setIsModalVisible(!isModalVisible);
        }}
        onCancel={() => {
          setIsModalVisible(!isModalVisible);
        }}
      >
        <PhotoAction
          attribute="none"
          stclass="8"
          stkey={state?.objStkey}
          pictures={pictures}
          setPhoto={setPhoto}
          setImage={setImage}
          setOpen={setOpen}
          open={open}
          deleteImage={deleteImage}
          uploadProps={uploadProps}
          setCameraOpen={setCameraOpen}
          cameraOpen={cameraOpen}
          isModalVisible={isModalVisible}
          setIsModalVisible={setIsModalVisible}
        />
      </Modal>
    </>
  );
}
