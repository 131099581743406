import { url } from "../../../constants";
import axios from "axios";
import moment from "moment";
import { string_to_double } from "../../../constants";

export const getChecks = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getAvailableOpenRoundsForToday`,
      {
        group: "class_g28",
        stClass: "15",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getRounds = async (stkey, nfeld9, nfeld10) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getRoundStatus`,
      {
        group: "28",
        stkey: stkey,
        active: 1,
        stclass: 91,
        nfeld9: nfeld9,
        nfeld10: nfeld10,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const checkTagID = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}checkTagIDForRound`,
      {
        ...values,
        checkTime: moment()?.format("YYYYMMDDHHmmss"),
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getLatestPhoto = async (key) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}downloadLatestFileMIMO`,
      {
        objID: key,
        stclass: 15,
        detclass: 0,
        group: 8,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFiles = async (key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}requestFileList`,
      {
        attribute: attribute,
        objID: key,
        group: 8,
        stClass: 15,
        detclass: 0,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const downloadFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}downloadFileMIMO`,
      {
        imageName: filename,
        objID: key,
        detclass: 0,
        stclass: 15,
        group: 8,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadFile = async (file, key, attribute) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const date = moment(moment()).format("DD.MM.YYYY");

    const time = moment(moment()).format("HH:mm");

    var a = time.substr(0, 2);
    var b = time.substr(3, 2);

    const response = await axios.post(
      `${url}uploadFileWithMIMO`,
      {
        file: file?.file,
        filename: file?.filename,
        attribute: attribute,
        detclass: 0,
        group: 8,
        stclass: 15,
        date: string_to_double(date),
        time: parseFloat(a + b),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: key,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const removeFile = async (key, filename) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}removeFile`,
      {
        stkey: key,
        cfeld1: filename,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
