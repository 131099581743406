import {
    Modal,
    Input,
    Form,
    Row,
    Col,
    Typography,
    message,
    Menu,
    Dropdown
  } from "antd";
  import { useRef,useState, useEffect} from "react";
  import IntlMessages from "util/IntlMessages";
  import { DownOutlined } from "@ant-design/icons";
import { editCostCenter } from "./network_requests";
  
  const success = () => {
    message.success("Die Dienststelle wurde bearbeitet.");
  };
  
  const error = () => {
    message.error("Ein Fehler ist passiert, bitte nochmal versuchen!");
  };
  
  export default function EditCostCenter(props) {
    const { costCenterItem, setEstate,fields } = props;
    const [numberRange, setNumberRange] = useState(null);
 
    
  
    const ref = useRef();
    const { Text } = Typography;

    useEffect(() => {
      setNumberRange(costCenterItem?.cfeld4 ? costCenterItem?.cfeld4 : null);
        
      }, [costCenterItem]);

    const number_range = (
      <Menu
        title="Choose field"
        onClick={(e) => {
          setNumberRange(e.key);
        }}
        style={{
          overflowY: "scroll",
          maxHeight: "250px",
        }}
      >
        {fields?.number_fields?.map((field) => {
          return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
        })}
      </Menu>
    );
  
    return (
      <Modal
        title={"Dienststelle bearbeiten"}
        visible={Boolean(costCenterItem)}
        centered
        destroyOnClose
        okText={<IntlMessages id="save" />}
        onOk={() => {
          // setOpen(!open);
          ref?.current?.submit();
        }}
        onCancel={() => {
          setEstate(null);
        }}
        style={{
          minWidth: "58vw",
        }}
      >
        <Form
          name="edit Cost center"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={{
            ...costCenterItem,
          }}
          style={{
            paddingLeft:"20px"
          }}
          onFinish={async (values) => {
            const response = await editCostCenter({
              ...costCenterItem,
              ...values,
              cfeld4: numberRange,
            });
            if (response?.success) {
              success();
            } else {
              error();
            }
            props.setUpdate(!props.update);
            setEstate(null);
          }}
          //   onFinishFailed={onFinishFailed}
          //   autoComplete="off"
        >
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={8}>
              <Form.Item
                name="cfeld1"
                label="Name/Type"
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={1} /> */}
            <Col xs={8}>
              <Form.Item
                name="cfeld2"
                label="Nummer"
                style={{ marginBottom: 0 ,marginLeft:"10px" }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item name="cfeld4" label="Rechnungsnummernkreis" style={{ marginBottom: 0 ,marginLeft:"10px" }}>
              <Dropdown overlay={number_range} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{numberRange}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
              </Form.Item>
            </Col>

          </Row>
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={24}>
              <Form.Item name="cfeld3" label="Kommentar">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        
         
        </Form>
      </Modal>
    );
  }
  