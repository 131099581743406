import React, { useState, useEffect } from "react";
import moment from "moment";
import "./CustomWeeklyCalendar.css";
import { getOrderByDays, reverseDateFormat } from "./network_Request";
import IntlMessages from "../../../../../../util/IntlMessages";
import {
  doubleDT_to_string,
  double_to_string,
} from "../../../../../../constants";
import { Popover } from "antd";
import { useHistory } from "react-router";

const CustomWeeklyCalendar = ({ dates, all }) => {
  const [weeklyData, setWeeklyData] = useState([]);
  const [users, setUsers] = useState([]);
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const generateWeek = (startDate) => {
        const startOfWeek = moment(startDate, "YYYYMMDD");
        const weekDays = Array.from({ length: 7 }, (_, i) =>
          startOfWeek.clone().add(i, "days").format("YYYY-MM-DD")
        );
        return weekDays;
      };

      try {
        const data = await getOrderByDays(dates);

        const startDate = reverseDateFormat(dates[0]);

        const weekDays = generateWeek(startDate);

        const userEvents = {};
        const userTeams = {};
        for (const date in data) {
          data[date]?.forEach((event) => {
            if (!userEvents[event.employee_name]) {
              userEvents[event.employee_name] = {};
              userTeams[event.employee_name] = event.employee_team;
            }
            if (all || event.absencetype === 0) {
              userEvents[event.employee_name][date] = userEvents[
                event.employee_name
              ][date]
                ? [...userEvents[event.employee_name][date], event]
                : [event];
            }
          });
        }

        setUsers(
          Object.keys(userEvents).map((user) => ({
            name: user,
            team: userTeams[user],
          }))
        );
        setWeeklyData({ weekDays, userEvents });
      } catch (error) {
        console.error("Error fetching weekly data:", error);
      }
    };

    fetchData();
  }, [dates, all]);

  return (
    <div className="weekly-calendar">
      <table>
        <thead>
          <tr>
            <th><IntlMessages id="absences.user" /></th>
            {weeklyData.weekDays &&
              weeklyData?.weekDays?.map((day) => (
                <th key={day}>{moment(day).format("DD ddd")}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {users?.map((user) => (
            <tr key={user?.name}>
              <td className="user-name">
                {user?.name}

                <br />
                <span style={{ fontSize: "10px" }}>{user?.team}</span>
              </td>
              {weeklyData?.weekDays?.map((day) => (
                <td key={day} className="day-cell">
                  {weeklyData?.userEvents[user?.name][day] ? (
                    weeklyData?.userEvents[user?.name][day]?.map(
                      (event, index) => (
                        <Popover
                          content={event?.order_place}
                          title={event.order_title}
                          trigger={event?.absencetype === 0 ? "hover" : "none"}
                        >
                          <div
                            key={index}
                            className={"event-blue"}
                            style={{
                              cursor: "pointer",
                              backgroundColor:
                                event?.absencetype === 2
                                  ? "#a4d363"
                                  : event?.absencetype === 1
                                  ? "#eed36f"
                                  : "#007bff",
                            }}
                            onClick={() => {
                              if (event?.absencetype === 0)
                                history?.push(
                                  `/main/baseData-Order?editOrderKey=${event?.order_stkey}`
                                );
                            }}
                          >
                            <div
                              style={{
                                fontSize: "11px",
                              }}
                            >
                              {event?.absencetype === 0
                                ? doubleDT_to_string(
                                    event?.planned_start
                                  )?.substring(11)
                                : double_to_string(event?.planned_start)}

                              {event?.absencetype === 0
                                ? " - " +
                                  doubleDT_to_string(
                                    event?.planned_end
                                  )?.substring(11)
                                : event?.absencetype === 1
                                ? " - " + double_to_string(event?.planned_end)
                                : ""}
                            </div>
                            <strong>
                              {event?.absencetype === 0
                                ? event.order_title?.length > 47
                                  ? event.order_title?.substring(0, 47) + "..."
                                  : event.order_title
                                : event?.absencetype === 1
                                ? <IntlMessages id="absences.holiday" />
                                : <IntlMessages id="absences.absence" />}
                            </strong>
                          </div>
                        </Popover>
                      )
                    )
                  ) : (
                    <div className="no-event">-</div>
                  )}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CustomWeeklyCalendar;
