// import { Row } from "antd";
// import { useEffect, useState } from "react";
// import { getStaff } from "./network_requests";
// import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

// export default function HolidayAbsenses() {
//   const [staff, setStaff] = useState([]);
//   const history = useHistory();

//   return <Row>Hello</Row>;
// }

import {
  Row,
  Typography,
  Col,
  Pagination,
  Spin,
  Tabs,
  Empty,
  Popconfirm,
  Tooltip,
  Button,
} from "antd";
import {
  getStaff,
  getStaffAbsences,
  getStaffHolidays,
  editHoliday,
  editAbsence,
  getStaffForOrder,
} from "./network_requests";
import { useEffect, useState } from "react";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
} from "@ant-design/icons";
import { FileExcelOutlined } from "@ant-design/icons";
import MoreActions from "../../../../extraComponents/MoreActions";
import { double_to_string } from "../../../../../constants";
import AddHoliday from "./addHoliday";
import AddAbsence from "./addAbsence";
import EditHoliday from "./editHoliday";
import EditAbsence from "./editAbsence";
import { set } from "lodash";

const { Text } = Typography;
const { TabPane } = Tabs;

export default function HolidayAbsenses() {
  const [holidayPage, setHolidayPage] = useState(1);
  const [absencesPage, setAbsencesPage] = useState(1);
  const [data, setData] = useState([]);
  const [staff, setStaff] = useState([]);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [openAddHoliday, setOpenAddHoliday] = useState(false);
  const [openEditHoliday, setopenEditHoliday] = useState(null);
  const [openAddAbsence, setOpenAddAbsence] = useState(false);
  const [openEditAbsence, setopenEditAbsence] = useState(null);
  const [updateData, setUpdateData] = useState(false);

  useEffect(() => {
    const fetchStaff = async () => {
      setLoad(true);
      const response = await getStaff();
      setLoad(false);

      if (response?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setStaff(response?.list);
      setUser(response?.list[0]?.stkey);
    };
    fetchStaff();
  }, []);

  useEffect(async () => {
    if (user) {
      setLoad(true);

      const holidays = await getStaffHolidays(
        user,
        (holidayPage - 1) * 10,
        holidayPage * 10
      );
      const absences = await getStaffAbsences(
        user,
        (absencesPage - 1) * 10,
        absencesPage * 10
      );
      if (absences?.status === 401) {
        localStorage.removeItem("user_id");
        history.go(0);
      }
      setData({
        holidays: holidays?.list,
        absences: absences?.list,
        holidaysCount: holidays?.count,
        absencesCount: absences?.count,
      });
      setLoad(false);
    }
  }, [user, holidayPage, absencesPage, updateData]);

  return (
    <>
      <AddHoliday
        open={openAddHoliday}
        setOpen={setOpenAddHoliday}
        updateData={updateData}
        setUpdateData={setUpdateData}
        user={user}
        setLoad={setLoad}
      />
      <EditHoliday
        open={openEditHoliday}
        setOpen={setopenEditHoliday}
        updateData={updateData}
        setUpdateData={setUpdateData}
        setLoad={setLoad}
      />
      <AddAbsence
        open={openAddAbsence}
        setOpen={setOpenAddAbsence}
        updateData={updateData}
        setUpdateData={setUpdateData}
        user={user}
        setLoad={setLoad}
      />
      <EditAbsence
        open={openEditAbsence}
        setOpen={setopenEditAbsence}
        updateData={updateData}
        setUpdateData={setUpdateData}
        setLoad={setLoad}
      />
      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}

      {!load ? (
        <>
        <Row
        style={{
          alignItems: "center",
          padding: "0px 3px",
        }}
      >
        <Col xs={20} style={{}}>
          <Text style={{ fontSize: "18px" }}>
            <IntlMessages id="absences.absencesAndHolidays" />
          </Text>
        </Col>

        </Row>
        <div style={{ border: "1px solid #eaeaea", margin: "15px 0px" }} />
          <Row
            style={{
              width: "100%",
            }}
          >
            <Col span={24}>
              <Tabs
                defaultActiveKey={user}
                tabPosition="left"
                style={{ height: "75vh", overflow: "auto" }}
                size="small"
                onChange={(key) => {
                  setUser(key);
                }}
              >
                {staff?.map((member, i) => {
                  return (
                    <TabPane tab={member?.cfeld1} key={member?.stkey}>
                      <>
                        <Row
                          style={{
                            backgroundColor: "#FAFAFA",
                            borderBottom: "1px solid #eaeaea",
                            fontWeight: 500,
                            padding: "10px 30px",
                            margin: "0 15px",
                          }}
                        >
                          <Col
                            span={19}
                            style={{
                              padding: 0,
                            }}
                          >
                            <Text><IntlMessages id="absences.holiday" /></Text>
                          </Col>
                          <Col
                            span={3}
                            style={{
                              padding: 0,
                            }}
                          >
                            <IntlMessages id="more_actions" />
                          </Col>
                        </Row>

                        {data?.holidays?.length === 0 ? (
                          <Empty description="Keine Urlaube gebucht!" />
                        ) : null}

                        {data?.holidays?.map((item) => {
                          return (
                            <Row
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid #eaeaea",
                                padding: "10px 30px",
                                margin: "0 15px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col
                                span={21}
                                style={{
                                  padding: 0,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setopenEditHoliday(item);
                                }}
                              >
                                {double_to_string(item?.nfeld3)}
                                {" - "}
                                {double_to_string(item?.nfeld4)}
                              </Col>
                              <Col
                                span={3}
                                style={{
                                  padding: 0,
                                }}
                              >
                                <MoreActions
                                  state={item}
                                  noPic={true}
                                  onDelete={async (data) => {
                                    const response = await editHoliday({
                                      ...data,
                                      active: 0,
                                    });
                                    setUpdateData(!updateData);
                                    return response;
                                  }}
                                  update={updateData}
                                  setUpdate={setUpdateData}
                                />
                                <DownOutlined
                                  size="small"
                                  style={{
                                    color: "#038fde",
                                    paddingLeft: "10px",
                                    fontSize: "10px",
                                  }}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                        <Row
                          style={{
                            backgroundColor: "#FAFAFA",
                            borderBottom: "1px solid #eaeaea",
                            padding: "10px 30px",
                            margin: "0 15px",
                          }}
                          justify="end"
                        >
                          <Button
                            type="primary"
                            size="middle"
                            style={{
                              margin: 0,
                            }}
                            onClick={() => {
                              setOpenAddHoliday(!openAddHoliday);
                            }}
                          >
                             <IntlMessages id="absence.addHoliday" />
                          </Button>
                        </Row>
                        <Row
                          style={{
                            backgroundColor: "#fff",
                            borderBottom: "1px solid #eaeaea",
                            padding: "10px 30px",
                            margin: "0 15px",
                          }}
                          justify="end"
                        >
                          <Pagination
                            defaultCurrent={1}
                            total={data?.holidaysCount}
                            current={holidayPage}
                            defaultPageSize={10}
                            style={{
                              margin: 0,
                            }}
                            onChange={(newpage) => {
                              setHolidayPage(newpage);
                            }}
                          />
                        </Row>
                      </>

                      <>
                        <Row
                          style={{
                            backgroundColor: "#FAFAFA",
                            borderBottom: "1px solid #eaeaea",
                            fontWeight: 500,
                            padding: "10px 30px",
                            margin: "80px 15px 0 15px",
                          }}
                        >
                          <Col
                            span={19}
                            style={{
                              padding: 0,
                            }}
                          >
                            <Text><IntlMessages id="absences.absences" /></Text>
                          </Col>
                          <Col
                            span={3}
                            style={{
                              padding: 0,
                            }}
                          >
                            <IntlMessages id="more_actions" />
                          </Col>
                        </Row>

                        {data?.absences?.length === 0 ? (
                          <Empty description="Keine Abwesenheiten eingetragen!" />
                        ) : null}

                        {data?.absences?.map((item) => {
                          return (
                            <Row
                              style={{
                                backgroundColor: "#fff",
                                borderBottom: "1px solid #eaeaea",
                                padding: "10px 30px",
                                margin: "0 15px",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Col
                                span={21}
                                style={{
                                  padding: 0,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  setopenEditAbsence(item);
                                }}
                              >
                                {double_to_string(item?.nfeld3)}
                                {" - "}
                                {double_to_string(item?.nfeld4)}
                              </Col>
                              <Col
                                span={3}
                                style={{
                                  padding: 0,
                                }}
                              >
                                <MoreActions
                                  state={item}
                                  noPic={true}
                                  onDelete={async (data) => {
                                    const response = await editAbsence({
                                      ...data,
                                      active: 0,
                                    });
                                    setUpdateData(!updateData);
                                    return response;
                                  }}
                                  update={updateData}
                                  setUpdate={setUpdateData}
                                />
                                <DownOutlined
                                  size="small"
                                  style={{
                                    color: "#038fde",
                                    paddingLeft: "10px",
                                    fontSize: "10px",
                                  }}
                                />
                              </Col>
                            </Row>
                          );
                        })}
                        <Row
                          style={{
                            backgroundColor: "#FAFAFA",
                            borderBottom: "1px solid #eaeaea",
                            padding: "10px 30px",
                            margin: "0 15px",
                          }}
                          justify="end"
                        >
                          <Button
                            type="primary"
                            size="middle"
                            style={{
                              margin: 0,
                            }}
                            onClick={() => {
                              setOpenAddAbsence(!openAddAbsence);
                            }}
                          >
                            <IntlMessages id="absence.addAbsence" />
                          </Button>
                        </Row>
                        <Row
                          style={{
                            backgroundColor: "#fff",
                            padding: "10px 30px 0 30px",
                            margin: "0 15px",
                          }}
                          justify="end"
                        >
                          <Pagination
                            defaultCurrent={1}
                            total={data?.absencesCount}
                            current={absencesPage}
                            defaultPageSize={10}
                            style={{
                              margin: 0,
                            }}
                            onChange={(newpage) => {
                              setAbsencesPage(newpage);
                            }}
                          />
                        </Row>
                      </>
                    </TabPane>
                  );
                })}
                {/* {[buildings?.map(i => (
            <TabPane tab={`Tab-${i}`} key={i} disabled={i === 28}>
              Content of tab {i}
            </TabPane>
          ))} */}
              </Tabs>
            </Col>
          </Row>
        </>
      ) : null}
    </>
  );
}
