import { Row, Typography, Col, Spin, Empty } from "antd";
import { getChecks } from "./network_requests";
import { useEffect, useState } from "react";
import { RightOutlined } from "@ant-design/icons";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { double_to_string } from "../../../constants";
import CheckDetail from "./CheckDetail";

const { Text } = Typography;

export default function CheckAppMobile(props) {
  const [checks, setChecks] = useState([]);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [checkDetail, setCheckDetail] = useState(null);

  useEffect(async () => {
    document.body.style.zoom = "100%";
    setLoad(true);

    const Check_ = await getChecks();

    if (Check_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }
    setChecks(Check_ ? Check_ : []);
    setLoad(false);
  }, [updateData]);

  return (
    <>
      {checkDetail ? (
        <CheckDetail check={checkDetail} setCheck={setCheckDetail} />
      ) : (
        <div
          style={{
            height: "95vh",
            backgroundColor: "#f3f7ff",
          }}
        >
          <>
            <div
              style={{
                backgroundColor: "#fff",
                padding: "20px",
                width: "100%",
              }}
            >
              <Typography
                style={{
                  fontSize: "24px",
                  fontWeight: 450,
                }}
              >
                Überprüfungen durchführen
              </Typography>
            </div>

            <div
              style={{
                backgroundColor: "#f3f7ff",
                left: 0,
                padding: "20px",
                width: "100%",
              }}
            >
              <Row style={{ padding: "0px 20px 0px 20px" }}>
                <Text style={{ color: "gray", textTransform: "uppercase" }}>
                  Offene Runden
                </Text>
              </Row>
            </div>
          </>

          {load ? (
            <div
              style={{
                marginTop: "10vh",
                justifyContent: "center",
                display: "flex",
                width: "100%",
              }}
            >
              <Spin />
            </div>
          ) : null}
          {!load ? (
            <>
              {checks?.length === 0 ? <Empty /> : null}

              {[...checks]?.map((check) => {
                return (
                  <Row
                    style={{
                      backgroundColor: "#fff",
                      // borderBottom: "1px solid #eaeaea",
                      padding: "20px 30px",
                      margin: "0",
                    }}
                  >
                    <Col xs={2} />
                    <Col
                      xs={18}
                      style={{
                        padding: 0,
                      }}
                    >
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setCheckDetail(check);
                        }}
                      >
                        {check?.cfeld1?.length > 33
                          ? `${check?.cfeld1?.substr(0, 32)}...`
                          : check?.cfeld1}
                      </Text>
                      <br />
                      <Text
                        style={{
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Bis {double_to_string(check?.nfeld1)}
                      </Text>
                    </Col>

                    <Col
                      xs={3}
                      style={{ display: "flex", justifyContent: "flex-end" }}
                    >
                      <RightOutlined
                        size="large"
                        onClick={() => {
                          setCheckDetail(check);
                        }}
                      />
                    </Col>
                  </Row>
                );
              })}
            </>
          ) : null}
        </div>
      )}
    </>
  );
}
