import {
  Button,
  Col,
  Row,
  Select,
  Typography,
  DatePicker,
  Popconfirm,
  Tooltip,
  message,
} from "antd";
import IntlMessages from "../../../../../util/IntlMessages";
import moment from "moment";
import { useHistory } from "react-router";
import { useState } from "react";
import { FileExcelOutlined, MailOutlined } from "@ant-design/icons";
import {
  getExcelForWorkOrders,
  createOrdersFromEmail,
} from "../workOrders/components/network_Request";
import CustomCalendar from "../workOrders/components/customCalender";
import { Checkbox } from "antd";

const { Text } = Typography;
const { RangePicker } = DatePicker;

function downloadToLocal(pdf, filename) {
  //const linkSource = `data:application/pdf;base64,${pdf}`;
  const linkSource = pdf;
  const downloadLink = document.createElement("a");
  const fileName = filename;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}

export default function WorkOrdersCalender() {
  const navigate = useHistory();
  const [filter, setFilter] = useState("planned");
  const [dates, setDates] = useState([
    moment()?.startOf("week").format("DDMMYYYY"),
    moment()?.endOf("week").format("DDMMYYYY"),
  ]);

  const [updateData, setUpdateData] = useState(false);
  const [all, setAll] = useState(true);

  return (
    <>
      <Row
        style={{
          alignItems: "center",
          padding: "0px 3px",
        }}
      >
        <Col xs={4} style={{}}>
          <Text style={{ fontSize: "18px" }}>
            <IntlMessages id="wordorder.workordersCalendar" />
          </Text>
        </Col>

        <Col xs={6}>
          <Button.Group
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            <Button
              style={{
                margin: 0,
              }}
              onClick={() => {
                setDates([
                  moment()?.startOf("week").format("DDMMYYYY"),
                  moment()?.endOf("week").format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.today" />
            </Button>
            <Button
              style={{
                margin: 0,
              }}
              onClick={() => {
                setDates([
                  moment(dates[0], "DDMMYYYY")
                    .add(-7, "days")
                    .startOf("isoWeek")
                    .format("DDMMYYYY"),
                  moment(dates[0], "DDMMYYYY")
                    .add(-7, "days")
                    .endOf("isoWeek")
                    .format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.back" />
            </Button>
            <Button
              style={{
                margin: 0,
              }}
              onClick={() => {
                setDates([
                  moment(dates[0], "DDMMYYYY")
                    .add(7, "days")
                    .startOf("isoWeek")
                    .format("DDMMYYYY"),
                  moment(dates[0], "DDMMYYYY")
                    .add(7, "days")
                    .endOf("isoWeek")
                    .format("DDMMYYYY"),
                ]);
              }}
            >
              <IntlMessages id="workorder.next" />
            </Button>
          </Button.Group>
        </Col>
        <Col
          xs={6}
          style={{
            color: "#2596be",
            fontSize: "16px",
            fontWeight: "bold",
          }}
        >
          <RangePicker
            format={"DD.MM.YYYY"}
            value={[moment(dates[0], "DDMMYYYY"), moment(dates[1], "DDMMYYYY")]}
            onChange={(values, formatString) => {
              const modifiedArray = formatString.map((date) => {
                return date.replace(/\./g, "");
              });
              setDates(modifiedArray);
            }}
          />
        </Col>
        <Col xs={4}>
          <Checkbox checked={all} onChange={(e) => setAll(e.target.checked)}>
            <IntlMessages id="wordorder.showAbsences" />
          </Checkbox>
        </Col>
        <Col xs={2} style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            size="small"
            type="primary"
            style={{
              margin: 0,
            }}
            onClick={() => {
              navigate.push("/main/baseData-Order");
            }}
          >
            <IntlMessages id="wordorder.newworkorder" />
          </Button>
        </Col>
      </Row>
      <div style={{ border: "1px solid #eaeaea", margin: "15px 0px" }} />
      <CustomCalendar dates={dates} all={all} />
    </>
  );
}
