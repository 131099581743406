import React, { useCallback, useEffect, useState, useRef } from "react";
import { Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { useHistory } from "react-router";
import { Input, Button, Space } from "antd";

const ExtScan = ({ update, setUpdate, setActive, setMessage }) => {
  const [status, setStatus] = useState("Pending");
  const history = useHistory();
  const [tagScaneed, setTagScanned] = useState("");
  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      <div
        style={{
          backgroundColor: "#fff",
          padding: "20px",
          width: "100%",
        }}
      >
        <Typography
          style={{
            color: "#038fde",
            cursor: "pointer",
          }}
          onClick={() => {
            setActive(false);
          }}
        >
          <ArrowLeftOutlined style={{ fontSize: "22px", fontWeight: "bold" }} />
        </Typography>
        <Typography
          style={{
            fontSize: "24px",
            fontWeight: 450,
            cursor: "pointer",
            marginTop: "10px",
          }}
        >
          {status === "Scanned Successful"
            ? "Successful"
            : status === "Pending"
            ? "Pending"
            : "Failed"}{" "}
          Scan
        </Typography>
      </div>
      <div
        style={{
          height: "80vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f3f7ff",
        }}
      >
        <Space
          direction="vertical"
          style={{ width: "100%", maxWidth: "400px", margin: "20px auto" }}
        >
          <label style={{ fontWeight: "bold", marginBottom: "8px" }}>
            Please scan the NFC tag with the external scanner
          </label>
          <Input
            ref={inputRef}
            placeholder="Enter here..."
            onChange={(e) => {
              setTagScanned(e.target.value);
            }}
          />
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={() => {
              setMessage(tagScaneed);
            }}
          >
            OK
          </Button>
        </Space>
      </div>
    </>
  );
};

export default ExtScan;
