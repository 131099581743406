import { Row, Typography, Col, Button, Pagination, Spin, Empty } from "antd";
import { getCostCenter,getFields } from "./network_requests";
import { useEffect, useState } from "react";
import { DownOutlined, RightSquareOutlined } from "@ant-design/icons";
import AddCostCenter from "./addCostCenter";
import EditCostCenter from "./editCostCenter";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import MoreActions from "../../../../../extraComponents/MoreActions";
import { deleteAll } from "./network_requests";

const { Text } = Typography;

export default function CostCenter() {
  const [costCenter, setCostCenter] = useState([]);
  const [addCostCenter, setAddCostCenter] = useState(false);
  const [editCostCenter, setEditCostCenter] = useState(null);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [updateData, setUpdateData] = useState(false);
  const [load, setLoad] = useState(false);
  const history = useHistory();
  const [fields, setFields] = useState({});

  useEffect(async () => {
    setLoad(true);
    const costCenter_ = await getCostCenter((page - 1) * 10);
    if (costCenter_?.status === 401) {
      localStorage.removeItem("user_id");
      history.go(0);
    }

    setCostCenter(costCenter_?.list ? costCenter_?.list : []);
    setCount(costCenter_?.count ? costCenter_?.count : 0);
    setLoad(false);
  }, [page, updateData]);

    useEffect(async () => {
      const number_fields = await getFields("billnorange");

      setFields({
        ...fields,
        number_fields: number_fields,
      });
    }, []);

  return (
    <>
      <AddCostCenter
        open={addCostCenter}
        setOpen={setAddCostCenter}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        fields={fields}
      />
      <EditCostCenter
        costCenterItem={editCostCenter}
        setEstate={setEditCostCenter}
        setLoad={setLoad}
        update={updateData}
        setUpdate={setUpdateData}
        fields={fields}
      />

      {load ? (
        <div
          style={{
            marginTop: "10vh",
            justifyContent: "center",
            display: "flex",
            width: "100%",
          }}
        >
          <Spin />
        </div>
      ) : null}
      {!load ? (
        <>
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
          >
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Name/Type
            </Col>
            <Col
              xs={4}
              style={{
                padding: 0,
              }}
            >
              Dienststelle
            </Col>

            <Col
              xs={15}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Text>
                <IntlMessages id="actions" />
              </Text>
            </Col>
          </Row>

          {costCenter?.length === 0 ? (
            <Empty description="Keine Dienststellen vorhanden!" />
          ) : null}

          {costCenter?.map((costCenterItem) => {
            return (
              <Row
                style={{
                  backgroundColor: "#fff",
                  borderBottom: "1px solid #eaeaea",
                  padding: "10px 30px",
                  margin: "0",
                }}
              >
                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditCostCenter(costCenterItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {costCenterItem?.cfeld1}
                  </Text>
                </Col>

                <Col
                  xs={4}
                  style={{
                    padding: 0,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setEditCostCenter(costCenterItem);
                  }}
                >
                  <Text
                    style={{
                      color: "#038fde",
                    }}
                  >
                    {costCenterItem?.cfeld2}
                  </Text>
                </Col>

                <Col
                  xs={16}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <MoreActions 
                  state={costCenterItem} 
                  onDelete={deleteAll} 
                  update={updateData}
                 setUpdate={setUpdateData}/>
                  <DownOutlined
                    size="small"
                    style={{
                      color: "#038fde",
                      paddingLeft: "10px",
                      fontSize: "10px",
                    }}
                  />
                </Col>
              </Row>
            );
          })}
          <Row
            style={{
              backgroundColor: "#FAFAFA",
              borderBottom: "1px solid #eaeaea",
              fontWeight: 500,
              padding: "10px 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Button
              size="small"
              type="primary"
              style={{
                margin: 0,
              }}
              onClick={() => {
                setAddCostCenter(!addCostCenter);
              }}
            >
              <IntlMessages id="workorder.costCenteritem.add" />
            </Button>
          </Row>
          <Row
            style={{
              backgroundColor: "#fff",
              padding: "10px 30px 0 30px",
              margin: "0",
            }}
            justify="end"
          >
            <Pagination
              defaultCurrent={1}
              total={count}
              current={page}
              defaultPageSize={10}
              style={{
                margin: 0,
              }}
              onChange={(newpage) => {
                setPage(newpage);
              }}
            />
          </Row>
        </>
      ) : null}
    </>
  );
}
