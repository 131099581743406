import {
    Modal,
    Input,
    Form,
    Row,
    Col,
    DatePicker,
    Typography,
    message,
    Dropdown,
    Menu,
  } from "antd";
  import { useRef, useState } from "react";
  import IntlMessages from "util/IntlMessages";
  import { DownOutlined } from "@ant-design/icons";
import { addCostCenter } from "./network_requests";
  
  const success = () => {
    message.success("Dienststelle wurde hinzugefügt.");
  };
  
  const error = () => {
    message.error("Ein Fehler ist aufgetreten - bitte erneut versuchen!");
  };
  
  export default function AddCostCenter(props) {
    const { open, setOpen, parent,fields } = props;
    const [numberRange, setNumberRange] = useState(null);
  
    const ref = useRef();
    const { Text } = Typography;

    const number_range = (
      <Menu
        title="Choose field"
        onClick={(e) => {
          setNumberRange(e.key);
        }}
        style={{
          overflowY: "scroll",
          maxHeight: "250px",
        }}
      >
        {fields?.number_fields?.map((field) => {
          return <Menu.Item key={field?.lovvalue}>{field?.lovvalue}</Menu.Item>;
        })}
      </Menu>
    );
  
    return (
      <Modal
        title={"Dienststelle anlegen"}
        visible={open}
        centered
        destroyOnClose
        okText={<IntlMessages id="save" />}
        onOk={() => {
          // setOpen(!open);
          ref?.current?.submit();
        }}
        onCancel={() => {
          setOpen(!open);
        }}
        style={{
          minWidth: "58vw",
        }}
      >
        <Form
          name="add Cost center"
          ref={ref}
          labelCol={{
            span: 24,
          }}
          wrapperCol={{
            span: 24,
          }}
          initialValues={
            {
              // remember: true,
            }
          }
          style={{
            paddingLeft:"20px"
          }}
          onFinish={async (values) => {
            const response = await addCostCenter(
              {
                ...values,
                cfeld4: numberRange,
              },
              parent
            );
            if (response?.success) {
              success();
            } else {
              error();
            }
            props.setUpdate(!props.update);
            setOpen(!open);
          }}
            // onFinishFailed={onFinishFailed}
            // autoComplete="off"
        >
          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={8}>
              <Form.Item
                name="cfeld1"
                label="Name/Type"
                style={{ marginBottom: 0 }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>
            {/* <Col xs={1} /> */}
            <Col xs={8}>
              <Form.Item
                name="cfeld2"
                label="Nummer"
                style={{ marginBottom: 0 ,marginLeft:"10px" }}
                // rules={[{ required: true, message: "The field cannot be empty" }]}
              >
                <Input />
              </Form.Item>
            </Col>

            <Col xs={8}>
              <Form.Item name="cfeld4" label="Rechnungsnummernkreis" style={{ marginBottom: 0 ,marginLeft:"10px" }}>
              <Dropdown overlay={number_range} trigger="click">
                <Row
                  style={{
                    border: "1px solid #d9d9d9",
                    height: "36px",
                    borderRadius: "6px",
                    margin: "0px",
                  }}
                >
                  <Col
                    span={21}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Text>{numberRange}</Text>
                  </Col>
                  <Col
                    span={3}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <DownOutlined style={{ color: "#d9d9d9" }} />
                  </Col>
                </Row>
              </Dropdown>
              </Form.Item>
            </Col>

           
          </Row>

          <Row style={{ width: "100%", height: "75px" }}>
            <Col xs={24}>
              <Form.Item name="cfeld3" label="Kommentar">
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    );
  }
  