import { Modal, Input, Form, Row, Col, DatePicker } from "antd";
import { useEffect, useRef, useState } from "react";
import { addHoliday, editHoliday } from "./network_requests";
import moment from "moment";
import IntlMessages from "util/IntlMessages";
import { useHistory } from "react-router";
import { double_to_string, string_to_double } from "../../../../../constants";

export default function EditHoliday(props) {
  const { open, setOpen, updateData, setUpdateData } = props;
  const history = useHistory();
  const ref = useRef();
  const [nfeld3, setnfeld3] = useState(moment(moment(), "DD.MM.YYYY"));
  const [nfeld4, setnfeld4] = useState(moment(moment(), "DD.MM.YYYY"));

  useEffect(() => {
    setnfeld3(
      open?.nfeld3
        ? moment(double_to_string(open?.nfeld3), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
    setnfeld4(
      open?.nfeld4
        ? moment(double_to_string(open?.nfeld4), "DD.MM.YYYY")
        : moment(moment(), "DD.MM.YYYY")
    );
  }, [open]);

  return (
    <Modal
      title={<>Edit Holiday</>}
      visible={open}
      centered
      destroyOnClose
      okText={<IntlMessages id="save" />}
      onOk={() => {
        setOpen(null);
        ref?.current?.submit();
      }}
      onCancel={() => {
        setOpen(null);
      }}
      style={{
        minWidth: "600px",
      }}
    >
      <Form
        name="edit holiday"
        ref={ref}
        labelCol={{
          span: 24,
        }}
        wrapperCol={{
          span: 24,
        }}
        initialValues={{
          ...open,
        }}
        onFinish={async (values) => {
          props?.setLoad(true);
          const response = await editHoliday({
            ...open,
            ...values,
            nfeld3: string_to_double(nfeld3),
            nfeld4: string_to_double(nfeld4),
          });
          if (response?.status === 401) {
            localStorage.removeItem("user_id");
            history.go(0);
          }
          setUpdateData(!updateData);
        }}
        //   onFinishFailed={onFinishFailed}
        //   autoComplete="off"
      >
        <Row>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item label={<IntlMessages id="absence.begin" />} style={{ marginBottom: 0 }}>
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld3}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld3(d);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={12} style={{ margin: 0 }}>
            <Form.Item label={<IntlMessages id="absence.end" />} style={{ marginBottom: 0 }}>
              <DatePicker
                style={{ width: "100%" }}
                defaultValue={nfeld4}
                format="DD.MM.YYYY"
                onChange={(e, d) => {
                  setnfeld4(d);
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          label={<IntlMessages id="comment" />}
          name="cfeld3"
          style={{ marginBottom: 0 }}
        >
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
}
