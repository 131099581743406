import { url } from "../../../constants";
import axios from "axios";
import moment from "moment";

export const sendTrackingInfo = async (vehicle) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}sendTrackingTagInfo`,
      {
        itemID: vehicle,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editTrackingDetails = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        group: 33,
        active: 1,
        stclass: 10,
        gisid: null,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCostCenter = async () => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getCostCenterListForOrder`,
      {
        group: "class_g33",
        stClass: "6",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getTrackingData = async (stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getTrackingItem`,
      {
        stKey: stkey,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const uploadImage = async (file, objID) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const date = moment().format("YYYYMMDD");
    const time = moment().format("HHmm");
    const response = await axios.post(
      `${url}uploadImage`,
      {
        filename: `${date}${time}_${objID}.png`,
        file: file?.slice(22),
        attribute: "signature",
        group: 20,
        stclass: 1,
        detclass: 0,
        date: parseInt(date),
        time: parseInt(time),
        sort: 100,
        bemerkung: "",
        userName: window.localStorage.getItem("uname"),
        objID: objID,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        group: 20,
        stkey: "",
        active: 1,
        stclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        group: 20,
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const deleteTask = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        ...values,
        active: 0,
        group: 20,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
