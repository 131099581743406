import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import green_icon from "../../../assets/Icons_waste/task_finished.png";
import red_icon from "../../../assets/Icons_waste/task_open.png";
import { Collapse } from "antd";
import { DownOutlined, UpOutlined } from "@ant-design/icons";

const greenIcon = L.icon({
  iconUrl: green_icon,
  iconSize: [30, 30],
  shadowSize: [50, 64],
});

const redIcon = L.icon({
  iconUrl: red_icon,
  iconSize: [30, 30],
  shadowSize: [50, 64],
});

// Component to render map with checkpoints
const MapWithCheckpoints = ({ checkpoints }) => {
  // Filter out invalid checkpoints and find the first valid one
  const validCheckpoints = checkpoints.filter(
    (checkpoint) => checkpoint.objnfeld1 !== 0 && checkpoint.objnfeld2 !== 0
  );

  const center =
    validCheckpoints.length > 0
      ? [validCheckpoints[0].objnfeld1, validCheckpoints[0].objnfeld2]
      : null;

  const { Panel } = Collapse;

  return (
    <>
      {center ? (
        <div style={{ margin: "20px" }}>
          <Collapse
            accordion
            expandIcon={({ isActive }) =>
              isActive ? <UpOutlined /> : <DownOutlined />
            }
          >
            <Panel
              header={
                <span
                  style={{
                    paddingLeft: "15px",
                  }}
                >
                  Map
                </span>
              }
              key="1"
            >
              <MapContainer
                style={{ height: "40vh", width: "100%" }}
                center={center}
                zoom={16}
                scrollWheelZoom={false}
                tap={false}
              >
                <TileLayer
                  url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                  subdomains={["mt1", "mt2", "mt3"]}
                />
                {/* Add markers for all valid checkpoints */}
                {validCheckpoints.map((checkpoint, index) => (
                  <Marker
                    key={index}
                    position={[checkpoint.objnfeld1, checkpoint.objnfeld2]}
                    icon={
                      Boolean(checkpoint?.erledigtDatum) ? greenIcon : redIcon
                    }
                  >
                    <Popup>
                      {checkpoint?.objcfeld1} <br />
                    </Popup>
                  </Marker>
                ))}
              </MapContainer>
            </Panel>
          </Collapse>
        </div>
      ) : null}
    </>
  );
};

export default MapWithCheckpoints;
