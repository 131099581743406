import { url } from "../../../../../../constants";
import axios from "axios";
import moment from "moment";

export const getStaff = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "4",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getCostCenter = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "6",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getVehicle = async (limitFrom) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "5",
        type: "none",
        limitFrom: limitFrom,
        limitTo: 10,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addStaff = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        group: 33,
        stkey: "",
        active: 1,
        stclass: 4,
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editStaff = async (values) => {
  try {
   
    const token = window.localStorage.getItem("user_id");
    const { detTable__, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        active: 1,
        group: "33",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const deleteAll = async (values) => {
  try {
    
    const token = window.localStorage.getItem("user_id");
   const { detTable__,active, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateDetail`,
      {
        active: 0,
        stclass: 1,
        stkey:"47D68CEA8CA340",
        detTable__: "cldetail_g33" ,
        ...restValues,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



export const addCostCenter = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        group: 33,
        stkey: "",
        active: 1,
        stclass: 6,
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



export const editCostCenter = async (values) => {
  try {
   
    const token = window.localStorage.getItem("user_id");
   const { detTable__, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        active: 1,
        group: "33",
        ...restValues,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};


export const addVehicle = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}commonInsertClass`,
      {
        group: 33,
        stkey: "",
        active: 1,
        stclass: 5,
        ...values,
        gisid: "",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );

    console.log(response.data)
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};



export const editVehicle = async (values) => {
  try {
   
    const token = window.localStorage.getItem("user_id");
   const { detTable__, ...restValues } = values;
    const response = await axios.post(
      `${url}commonUpdateClass`,
      {
        active: 1,
        group: "33",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getFields = async (val) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getListOfValuesByID`,
      {
        lovgrkey: val,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};