import { url, string_to_double } from "../../../../../constants";
import axios from "axios";
import moment from "moment";

export const getStaffForOrder = async (stKey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}getStaffForOrder`,
      {
        group: "class_g33",
        stClass: "1",
        stKey: stKey,
        detail: "cldetail_g33",
        detClass: "4",
        type: "none",
        limitFrom: 0,
        limitTo: 100,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    console.log(response.data);
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getStaff = async () => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}selFromClassCommon`,
      {
        group: "class_g33",
        stClass: "4",
        type: "none",
        limitFrom: "0",
        limitTo: "100",
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getStaffHolidays = async (stkey, from, to) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}getHolidayForStaff`,
      {
        group: "class_g33",
        stClass: "4",
        stKey: stkey,
        detail: "cldetail_g33",
        detClass: "1",
        type: "none",
        limitFrom: from,
        limitTo: to,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const getStaffAbsences = async (stkey, from, to) => {
  try {
    const token = window.localStorage.getItem("user_id");

    const response = await axios.post(
      `${url}getAbsenceForStaff`,
      {
        group: "class_g33",
        stClass: "4",
        stKey: stkey,
        detail: "cldetail_g33",
        detClass: "2",
        type: "none",
        limitFrom: from,
        limitTo: to,
        orderBy: "cfeld1",
        cfields: [],
        nfields: [],
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addHoliday = async (values, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}addHolidayForStaff`,
      {
        detTable__: "cldetail_g33",
        detkey: "",
        active: 1,
        stkey: stkey,
        stclass: 4,
        detclass: 1,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editHoliday = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}updateHolidayForStaff`,
      {
        ...values,
        detTable__: "cldetail_g33",
        stclass: 4,
        detclass: 1,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const addAbsence = async (values, stkey) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}addAbsenceForStaff`,
      {
        detTable__: "cldetail_g33",
        detkey: "",
        active: 1,
        stkey: stkey,
        stclass: 4,
        detclass: 2,
        gisid: " ",
        ...values,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};

export const editAbsence = async (values) => {
  try {
    const token = window.localStorage.getItem("user_id");
    const response = await axios.post(
      `${url}updateAbsenceForStaff`,
      {
        ...values,
        detTable__: "cldetail_g33",
        stclass: 4,
        detclass: 2,
        gisid: " ",
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    return response.data;
  } catch (error) {
    console.log(error);
    return error.response;
  }
};
